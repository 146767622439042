.TransitionFade {
  opacity: 0;

  &_State {
    &_Enter {
      opacity: 0;
    }
    &_EnterActive {
      transition: opacity 200ms ease-in-out;
      opacity: 1;
    }

    &_Exit {
      opacity: 1;
    }

    &_ExitActive {
      opacity: 0;
      transition: opacity 200ms ease-in-out;
    }
  }
}

.TransitionSlideRight {
  transform: translateX(-100%);

  &_State {
    &_Enter {
      transform: translateX(-100%);
    }

    &_EnterActive {
      transform: translateX(0);
      transition: transform 300ms ease-in-out;
    }

    &_Exit {
      transform: translateX(0);
      opacity: 1;
    }

    &_ExitActive {
      transform: translateX(-100%);
      transition: transform 300ms ease-in-out;
    }
  }
}

.TransitionSlideDown {
  transform: translateY(-100%);

  &_State {
    &_Enter {
      transform: translateY(-100%);
    }

    &_EnterActive {
      transform: translateY(0);
      transition: transform 300ms ease-in-out;
    }

    &_Exit {
      transform: translateY(0);
      opacity: 1;
    }

    &_ExitActive {
      transform: translateY(-100%);
      transition: transform 300ms ease-in-out;
    }
  }
}

.TransitionSlideUp {
  transform: translateY(100%);

  &_State {
    &_Enter {
      transform: translateY(100%);
    }

    &_EnterActive {
      transform: translateY(0);
      transition: transform 300ms ease-in-out;
    }

    &_Exit {
      transform: translateY(0);
      opacity: 1;
    }

    &_ExitActive {
      transform: translateY(100%);
      transition: transform 300ms ease-in-out;
    }
  }
}
