@import "./_animations";

@mixin fixed-position($zIndex: 1) {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zIndex;
}

@mixin flex-centering() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin hide-scrollbar() {
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

@mixin animated-bg-gradient() {
  background-image: linear-gradient(
    90deg,
    transparent 0%,
    rgba(255, 255, 255, 0.15) 50%,
    transparent 100%
  );
  background-size: 300%;
  animation: moveBackroundHorizontal 2s ease-in infinite;
}

@mixin header-gradient() {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    180deg,
    #121212 0%,
    rgba(18, 18, 18, 0) 100%
  );
  z-index: -1;
}
