.final-scene {
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .final-scene-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    opacity: 0.3;
    filter: blur(4px);
    z-index: 1;
  }
  
  .final-scene-content {
    position: relative;
    z-index: 2;
    background-color: rgba(44, 44, 44, 0.9);
    border-radius: 12px;
    padding: 40px;
    max-width: 600px;
    width: 100%;
    text-align: center;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.3);
  }
  
  .final-scene-title {
    font-size: 36px;
    margin-bottom: 30px;
    font-weight: bold;
    
    &.win {
      color: #2ecc71;
      text-shadow: 0 0 10px rgba(46, 204, 113, 0.3);
    }
    
    &.lose {
      color: #e74c3c;
      text-shadow: 0 0 10px rgba(231, 76, 60, 0.3);
    }
  }
  
  .score-display {
    margin-bottom: 30px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    
    .score-label {
      font-size: 20px;
      color: #bdc3c7;
      margin-right: 10px;
    }
    
    .score-value {
      font-size: 32px;
      font-weight: bold;
      color: #3498db;
    }
  }
  
  .final-scene-message {
    margin-bottom: 40px;
    color: #ecf0f1;
    line-height: 1.6;
    
    p {
      margin-bottom: 15px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  .final-scene-actions {
    display: flex;
    gap: 20px;
    justify-content: center;
  }
  
  .action-button {
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    
    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    
    &:active {
      transform: translateY(0);
    }
    
    &.restart {
      background-color: #3498db;
      color: white;
      
      &:hover {
        background-color: #2980b9;
      }
    }
    
    &.back {
      background-color: #34495e;
      color: white;
      
      &:hover {
        background-color: #2c3e50;
      }
    }
  }
  
  @media (max-width: 480px) {
    .final-scene-content {
      padding: 20px;
    }
    
    .final-scene-title {
      font-size: 28px;
    }
    
    .final-scene-actions {
      flex-direction: column;
    }
    
    .action-button {
      width: 100%;
    }
  }