.QuizPrompt {
  display: flex;
  flex-direction: column;
  gap: 16rem;
  width: 100%;

  &__TextWrapper {
    display: flex;
    flex-direction: column;
    gap: 8rem;

    > :nth-child(2) {
      width: 238rem;
    }
  }

  &__InputWrapper {
    display: flex;
    gap: 10rem;

    input {
      flex: 1;
    }
  }
}
