@import "../../theme/_vars";

.NavBar {
  width: 100%;
  box-sizing: border-box;
  padding: 10rem 55rem 14rem;
  border-top-left-radius: 16rem;
  border-top-right-radius: 16rem;
  background-color: white;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $z-index-navbar;

  &__NavButton {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    padding: 0 2rem;
    font-size: 12rem;
    font-family: $ff-roboto;
    line-height: 12rem;
    align-items: center;
    color: #444;
    font-weight: 500;

    svg {
      width: 24rem;
      height: 24rem;
      fill: none;
      stroke: #444;
    }

    &_IsSelected {
      color: #121212;

      svg {
        fill: #fff730;
        stroke: #121212;
      }
    }
  }
}
