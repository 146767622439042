@import "../../theme/_vars";
@import "../../theme/_mixins";

.LeaderBoardScreen {
  &__HeaderText {
    color: #fff;
    font-size: 28rem;
    font-weight: 500;
    font-family: $ff-yanone-kaffeesatz;
    line-height: 26rem;
    margin-bottom: 20rem;
  }

  &__QuizTitle {
    color: rgba(255, 255, 255, 0.5);
    font-family: $ff-roboto;
    font-size: 16rem;
    font-weight: 600;
    line-height: 18rem;
    margin-bottom: 20rem;
  }

  &__List {
    display: flex;
    flex-direction: column;
    gap: 4rem;
  }
}

.Player {
  display: flex;
  gap: 10rem;
  padding: 10rem 20rem;
  align-items: center;
  color: white;
  border-radius: 12px;
  border: 2px solid transparent;
  font-family: $ff-roboto;

  &_Sticky {
    position: sticky;
    bottom: 0;

    &_Top {
      bottom: 64rem;
    }
  }

  &_Type {
    &_Friend {
      border-color: $color-yellow;
      background-color: $color-deep-blue;
    }

    &_I {
      background-color: $color-yellow;
      color: $color-deep-blue;

      .Player__Index {
        color: $color-deep-blue;
      }
    }

    &_Stub {
      @include animated-bg-gradient();

      & > * {
        opacity: 0;
      }
    }
  }

  &__Index {
    width: 32rem;
    font-size: 20px;

    &_Yellow {
      color: $color-yellow;
    }
  }

  &__NameContainer {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: flex-start;
  }

  &__Name {
    width: 135rem;
    font-size: 16rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__Scores {
    font-family: $ff-yanone-kaffeesatz;
    font-size: 24rem;
    font-weight: 700;
  }

  &__FriendLabel {
    border-radius: 10rem;
    background: $color-yellow;
    color: $color-deep-blue;
    font-size: 12rem;
    padding: 3rem 6rem;
  }
}
