@import "../../theme/_vars";

.Quiz {
  display: flex;
  flex-direction: column;
  gap: 20rem;
  flex-grow: 1;

  &__TopContainer {
    position: relative;
  }

  &__Overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: rgba($color: $color-deep-blue, $alpha: 0.75);
  }
}

.Question {
  display: flex;
  flex-direction: column;
  gap: 23rem;
  flex-grow: 1;

  &_Explanation {
    gap: 16rem;

    .Question__Text {
      flex-grow: 0;
    }
  }

  &__Text {
    color: #fff;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-family: $ff-yanone-kaffeesatz;
    font-size: 28rem;
    font-weight: 500;
    line-height: 26rem;
  }

  &__ExplanationText {
    flex-grow: 1;
    position: relative;
  }

  &__ExplanationGradient {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50rem;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(35, 37, 49, 0.75) 100%
    );
  }

  &__AnswersContainer {
    display: flex;
    flex-direction: column;
    gap: 14rem;
  }
}

.Answer {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 54rem;
  padding: 11rem 50rem 11rem 20rem;
  box-sizing: border-box;
  border-radius: 16px;
  background-color: #fff;
  font-family: $ff-roboto;
  font-size: 14rem;
  font-weight: 500;
  line-height: 16rem;
  cursor: pointer;
  transition-property: background-color, color, box-shadow;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;

  > svg {
    position: absolute;
    width: 16rem;
    height: 16rem;
    top: 50%;
    right: 20rem;
    transform: translateY(-50%);
  }

  &_State {
    &_Correct {
      color: #121212;
      background-color: $color-green;
      box-shadow: inset 0 0 0 2rem #fff;
    }

    &_Incorrect {
      color: #fff;
      box-shadow: inset 0 0 0 2rem #fff;
      background-color: $color-red;
    }
  }

  &__Avatar {
    position: absolute;

    &_Friend {
      top: -6rem;
      left: -4rem;
      box-shadow: 0 0 0 2rem white;

      &_Combined {
        left: 15rem;
      }
    }

    &_My {
      top: -6rem;
      left: -4rem;
      box-shadow: 0 0 0 2rem #fff730;
    }
  }
}

.Timer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: $ff-roboto;

  svg {
    width: 58rem;
    height: 58rem;
  }

  $length: 164; // 164 = 2*PI*R -- length of a circle

  &__Loader {
    stroke-dasharray: $length;
    stroke-dashoffset: $length;
    transform: rotate(-90deg);
    transform-origin: center;
    animation: linear timeout;

    &_Paused {
      animation-play-state: paused;
    }

    &_Counter {
      transition: stroke-dashoffset 200ms ease-in-out;
    }
  }

  @keyframes timeout {
    from {
      stroke-dashoffset: 0;
    }

    to {
      stroke-dashoffset: $length;
    }
  }

  &__Counter {
    position: absolute;
    color: var(--mode-color);
    font-size: 16rem;
    line-height: 16rem;
  }
}

.CompetitonScores {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__Counter {
    color: var(--mode-color);
    font-family: $ff-yanone-kaffeesatz;
    font-size: 20rem;
    font-weight: 500;
    line-height: 16rem;
  }

  &__Container {
    width: 80rem;
    border-radius: 27rem;
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    gap: 12rem;
    align-items: center;

    &_My {
      text-align: left;

      & > [data-role="avatar"] {
        box-shadow: 0 0 0 2.5rem $color-yellow;
      }
    }

    &_Friend {
      text-align: right;
      flex-direction: row-reverse;
    }
  }
}
