.Skeleton {
  display: flex;
  flex-direction: column;
  gap: 8rem;

  &__Item {
    background-color: rgba(255, 255, 255, 0.24);
    height: 14rem;
    border-radius: 11rem;
    background-image: linear-gradient(
      90deg,
      transparent 0%,
      rgba(255, 255, 255, 0.15) 50%,
      transparent 100%
    );
    background-size: 300%;
    animation: gradient 2s ease-in infinite;

    &_1 {
      height: 23rem;
      border-radius: 17rem;
      margin: 0 46rem;
    }

    &_2 {
      height: 23rem;
      border-radius: 17rem;
      margin: 0 70rem 7rem;
    }

    &_3 {
      margin-right: 48rem;
    }
  }
}

@keyframes gradient {
	0% {
		background-position-x: 0%;
	}
	100% {
		background-position-x: -150%;
	}
}

