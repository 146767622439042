@import "../../theme/_vars";

.ProfileSettingsScreen {
  padding: 80rem 20rem 26rem;
  box-sizing: border-box;
  text-align: center;

  &__Title {
    font-family: $ff-yanone-kaffeesatz;
    font-size: 28rem;
    font-weight: 700;
    line-height: 26rem;
    text-transform: uppercase;
    color: #fff;
    margin-bottom: 40rem;
    text-align: center;
  }

  &__ButtonGo {
    margin: 38rem auto 20rem;
  }
}
