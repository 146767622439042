@import "../../theme/_vars";

.QuizModeSelector {
  display: flex;
  flex-direction: column;
  gap: 16rem;
}

.Selector {
  display: flex;
  justify-content: center;
  gap: 30rem;

  &__Item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8rem;
    transition: opacity 250ms ease-in-out;

    &_State {
      &_Selected {
        [data-role="image"] {
          box-shadow: 0 0 0 3rem #fff;
        }
      }

      &_Unselected {
        opacity: 0.8;
      }
    }
  }

  &__ItemImage {
    width: 60rem;
    height: 60rem;

    & > [data-role="image"] {
      border-radius: 50%;
      transition: box-shadow 250ms ease-in-out;
    }
  }

  &__ItemLabel {
    font-family: $ff-roboto;
    font-size: 14rem;
    font-weight: 500;
    line-height: 18rem;
    color: #fff;
  }
}
