@import "../../theme/_mixins";
@import "../../theme/_vars";

.ListLayout {
  display: flex;
  flex-direction: column;
  background-color: $color-deep-blue;

  &__Header {
    display: flex;
    align-items: flex-start;
    position: absolute;
    height: 72rem;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 20rem;
    box-sizing: border-box;
  }

  &__HeaderGradient {
    @include header-gradient();
  }

  &_Pos {
    &_Fixed {
      @include fixed-position($z-index-list-layout);
    }

    &_Static {
      width: 100%;
      height: 100%;
    }
  }

  &__Content {
    flex-grow: 1;
    overflow: auto;
    padding: 72rem 20rem 80rem;
  }

  &__Title {
    margin-bottom: 20rem;
  }

  &__BottomSpace {
    height: 20rem;
  }
}
