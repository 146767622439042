@import "../../theme/_mixins";

.CategoriesList {
  display: flex;
  flex-direction: column;
  gap: 16rem;

  &__Content {
    display: flex;
    gap: 8rem;
    overflow: auto;
    padding: 0 20rem;
    margin: 0 -20rem;
    @include hide-scrollbar();
  }
}

.Category {
  width: 140rem;
  height: 80rem;
  border-radius: 16rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  &_View_Stub {
    @include animated-bg-gradient();
  }
}
