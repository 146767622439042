@import "../../../theme/_vars";

.Input {
  box-sizing: border-box;
  height: 60rem;
  padding: 20rem 16rem;
  font-size: 16rem;
  font-weight: 400;
  font-family: $ff-roboto;
  border-radius: 12rem;
  border: none;
  min-width: 50rem;

  &:focus {
    outline: none;
  }

  &::placeholder {
    font-weight: 450;
  }

  &_IsBlocked {
    pointer-events: none;
  }
}
