.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    
    &.fullscreen {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1000;
    }
  }
  
  .spinner {
    position: relative;
    
    &.small {
      width: 30px;
      height: 30px;
    }
    
    &.medium {
      width: 50px;
      height: 50px;
    }
    
    &.large {
      width: 70px;
      height: 70px;
    }
  }
  
  .spinner-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 5px solid #f3f3f3;
    border-top: 5px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }