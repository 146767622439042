@import "../../../theme/_vars";

.Select {
  font-family: $ff-roboto;
  font-size: 16rem;
  font-weight: 400;
  line-height: 18rem;
  text-align: left;

  [class$="-control"] {
    height: 54rem;
    border-radius: 16rem;
  }

  [class$="-indicatorSeparator"] {
    display: none;
  }

  [class$="-menu"] {
    overflow: hidden;
    border-radius: 16rem;
  }

  [class$="-option"] {
    font-family: $ff-roboto;
    font-size: 12rem;
    font-weight: 400;
    color: $color-deep-gray;
  }
}
