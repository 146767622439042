@import "../../theme/_mixins";
@import "../../theme/_vars";

.AuthScreen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20rem 28rem;
  box-sizing: border-box;
  background-color: $color-deep-blue;
  @include fixed-position($z-index-auth-screen);

  &__Image {
    width: 200rem;
    height: 200rem;
    mix-blend-mode: lighten;
  }

  &__Title {
    font-family: $ff-yanone-kaffeesatz;
    font-size: 28rem;
    font-style: normal;
    font-weight: 700;
    line-height: 26rem;
    text-transform: uppercase;
    margin: 50rem 0 20rem;
    color: #fff;
    white-space: pre-wrap;
    text-align: center;
  }

  &__Description {
    text-align: center;
    color: $color-gray;
    font-family: $ff-roboto;
    font-size: 16rem;
    font-weight: 400;
    line-height: 18rem;
    margin-bottom: 60rem;
  }
}
