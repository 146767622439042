@import "../../theme/_vars";
@import "../../theme/_mixins";
@import "../../theme/_animations";

.Header {
  padding: 20rem;
  box-sizing: border-box;
  top: 0;
  left: 0;
  width: 100%;
  height: 75rem;
  position: fixed;
  display: flex;
  align-items: center;
  display: flex;
  z-index: 2;

  &__Gradient {
    @include header-gradient();
  }

  &__Anon {
    display: flex;
    align-items: center;
    margin-right: auto;
    position: relative;
    width: 100%;

    [data-role="app-mode-select"] {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &__User {
    position: absolute;
    top: 20rem;
    left: 20rem;
    display: flex;
    align-items: center;
    gap: 10rem;
  }

  &__UserName {
    color: #ccc;
    font-size: 14rem;
    font-weight: 500;
    font-family: $ff-roboto;
    max-width: 134rem;
  }

  &__AnonProfile {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__ProfileIcon {
    width: 32rem;
    height: 32rem;
    border-radius: 50%;
    margin-right: 10rem;
    background-color: $color-yellow;
    @include flex-centering();

    svg {
      width: 16rem;
      height: 16rem;
    }
  }

  &__Avatar {
    box-shadow: 0 0 0 2rem $color-yellow;
  }

  &__Label {
    color: $color-gray;
    font-family: $ff-roboto;
    font-size: 16rem;
    font-weight: 500;
    line-height: 16rem;
  }

  &__Coins {
    margin-left: auto;
    height: 23rem;
    padding: 7rem 3rem 5rem 9rem; // by design
    border-radius: 23rem;
    border: 1rem solid rgba(255, 255, 255, 0.25);
    animation: fadeIn 250ms ease-in-out forwards;
    @include flex-centering();

    svg {
      width: 26rem;
      height: 26rem;
    }
  }

  &__Lang {
    margin-left: auto;
    cursor: pointer;

    [class$="-menu"] {
      width: calc(100vw - 40rem);
      right: 0;
      top: 120%;
    }
  }

  &__SelectedLang {
    width: 32rem;
    height: 32rem;
    box-shadow: inset 0 0 0 1rem rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    color: $color-yellow;
    font-family: $ff-roboto;
    font-size: 12rem;
    font-weight: 500;
    line-height: 22rem;
    text-transform: capitalize;
    @include flex-centering();
  }

  &__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
}
