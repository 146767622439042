@import "../../theme/_mixins";
@import "../../theme/_vars";

.BeforeQuizLanding {
  @include fixed-position($z-index-quiz-generation);
  display: flex;
  flex-direction: column;

  &__Gradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(
      65.16% 50.7% at 50% -4.61%,
      rgba(18, 18, 18, 0) 0%,
      #121212 100%
    );
    pointer-events: none;
    z-index: -1;
  }

  &__Content {
    padding: 30rem 20rem 94rem;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__GenerationText {
    margin-bottom: 20rem;
  }

  &__SkeletonWrapper {
    margin-bottom: 26rem;
  }

  &__Title {
    margin-bottom: 16rem;
  }

  &__Description {
    margin-bottom: 30rem;
  }

  &__BackWrapper {
    position: absolute;
    top: 30rem;
    left: 20rem;
  }
}

.DuelModeLabel {
  display: flex;
  gap: 6rem;
  padding: 6rem 10rem;
  align-items: center;
  justify-content: center;

  &__Image {
    width: 24rem;
    height: 24rem;
    border-radius: 50%;
    overflow: hidden;
    font-family: $ff-roboto;
    font-size: 14rem;
    font-weight: 400;
    line-height: 18rem;
  }
}

.FriendCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
  margin-bottom: 30rem;

  &__Avatar {
    border: 2rem solid var(--mode-color);
  }

  &__Name {
    color: var(--mode-color);
  }
}
