@import "../../theme/_mixins";
@import "../../theme/_vars";

.QuizCompleted {
  @include fixed-position($z-index-quiz-complete);
  background-color: $color-deep-blue;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &__Header {
    padding: 20rem;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    height: 72rem;
  }

  &__Content {
    display: flex;
    flex-direction: column;
    gap: 30rem;
    flex-grow: 1;
    padding: 8rem 20rem 90rem;
  }

  &__BubbleButtonsWrapper {
    display: flex;
    gap: 8rem;

    > * {
      flex-grow: 1;
    }
  }
}

.Share {
  margin: 0 20rem;
  display: flex;
  gap: 15rem;
  color: #ccc;
  font-size: 14rem;
  font-weight: 400;
  font-family: $ff-roboto;
  line-height: 16rem;
  align-items: center;

  &__Highlighted {
    color: var(--mode-color);
  }
}

.BubbleButton {
  position: relative;
  padding: 8rem 0 12rem 12rem;
  box-shadow: inset 0 0 0 1rem white;
  border-radius: 20rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-width: 156rem;

  [data-role="text"] {
    text-align: left;
  }

  &_Clickable {
    cursor: pointer;
  }

  &_Type {
    &_Outlined {
      color: white;
      stroke: white;
    }

    &_Filled {
      background-color: white;
      color: #121212;
      stroke: #121212;
    }
  }

  svg {
    width: 24rem;
    height: 24rem;
  }

  &__Counter {
    display: flex;
    gap: 4rem;
    align-items: flex-end;
    margin: 10rem 0rem 4rem;
  }
}

.SingleInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 85rem;

  > :first-child {
    margin-bottom: 16rem;
  }

  > :nth-child(2) {
    margin-bottom: 20rem;
  }

  &__Scores {
    > :first-child {
      color: var(--mode-color);
      font-size: 32rem;
      font-family: $ff-yanone-kaffeesatz;
    }
  }
}

.DuelInfo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20rem;

  &__Stats {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6rem;
    font-family: $ff-roboto;

    &__Row {
      display: grid;
      grid-template-columns: 1fr 24rem 1fr;
      column-gap: 12rem;
      align-items: center;
      font-size: 16rem;
      font-weight: 500;
      color: white;

      svg {
        width: 24rem;
        height: 24rem;
        stroke: var(--mode-color);
      }

      > :first-child {
        text-align: right;
      }
    }

    > :nth-child(3) svg {
      stroke-width: 0;
      fill: var(--mode-color);
    }
  }
}

.Players {
  width: 108rem;
  height: 88rem;
  display: flex;
  align-items: flex-end;
  position: relative;

  &__Wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__CrownIcon {
    position: absolute;
    width: 50rem;
    height: 34rem;
    background-size: cover;
    display: none;
  }

  &__Item {
    position: relative;

    &:first-child {
      .Players__CrownIcon {
        top: -32rem;
        left: -12rem;
      }
    }

    &:last-child {
      .Players__CrownIcon {
        top: -30rem;
        left: 22rem;
        transform: scaleX(-1);
      }
    }

    &_State_Winner {
      .Players__CrownIcon {
        display: block;
      }
    }

    &_Color {
      &_Yellow {
        & > [data-role="avatar"] {
          box-shadow: 0 0 0 2rem #fff730;
        }
      }

      &__White {
        & > [data-role="avatar"] {
          box-shadow: 0 0 0 2rem white;
        }
      }
    }
  }

  &__Coins {
    position: absolute;
    top: 50%;
    right: 130rem;
    white-space: nowrap;
    transform: translateY(-50%);

    [data-role="text"] {
      color: var(--mode-color);
      text-align: right;
    }

    > :nth-child(2) {
      position: relative;
      top: -5rem;
    }
  }
}
