@import "../../theme/_mixins";
@import "../../theme/_vars";
@import "../../theme/_animations";

.QuizScreen {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 20rem 20rem 64rem;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  animation: fadeIn 250ms ease-in-out forwards;
  overflow: auto;

  &__Header {
    display: flex;
    gap: 16rem;
    justify-content: center;
    height: 44rem;
    min-height: 44rem;
    padding: 0 20rem;
    align-items: center;
    z-index: 1;
    margin-bottom: 24rem;
  }
}

.QuizFriendShare {
  @include fixed-position($z-index-quiz-share);
  background-color: #121212;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  color: white;
  padding: 40rem 20rem;
  align-items: center;
  text-align: center;

  &__Gradient {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: radial-gradient(
      94.76% 75% at 53.33% 9.69%,
      rgba(18, 18, 18, 0) 0%,
      #121212 100%
    );
    z-index: -1;
  }

  &__Welcome {
    font-size: 16rem;
  }

  &__Title {
    font-size: 34rem;
    margin-bottom: 50rem;
  }

  &__Desc {
    font-size: 16rem;
    margin-bottom: 20rem;

    > span {
      color: #fff730;
    }
  }
}
