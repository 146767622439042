@import "../../../theme/_mixins";

.Image {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;

  & > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 250ms ease-in-out;

    :global(.safari) & {
      transition: none;
    }
  }

  &__AnimatedStub {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @include animated-bg-gradient();
  }
}
