.Avatar {
  border-radius: 50%;
  background-color: white;
  text-transform: uppercase;
  color: black;
  text-align: center;
  background-position: center;
  background-size: contain;

  &_Size {
    &_24 {
      width: 24rem;
      height: 24rem;
      line-height: 24rem;
      font-size: 20rem;
    }

    &_32 {
      width: 32rem;
      height: 32rem;
      line-height: 32rem;
      font-size: 28rem;
    }

    &_40 {
      width: 40rem;
      height: 40rem;
      line-height: 40rem;
      font-size: 36rem;
    }

    &_50 {
      width: 50rem;
      height: 50rem;
      line-height: 50rem;
      font-size: 46rem;
    }

    &_60 {
      width: 60rem;
      height: 60rem;
      line-height: 60rem;
      font-size: 56rem;
    }
  }

  &_NA {
    &.Avatar_Size {
      &_24 {
        font-size: 10rem;
      }

      &_40 {
        font-size: 18rem;
      }

      &_60 {
        font-size: 26rem;
      }
    }
  }
}
