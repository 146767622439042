.quest-screen {
    position: relative;
    min-height: 100vh;
    background-color: #1e1e1e;
    color: #ffffff;
    padding: 20px;
    
    @media (max-width: 768px) {
        padding: 10px;
    }
} 