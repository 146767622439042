.Loader {
  border-radius: 50%;
  // width: 50rem;
  // height: 50rem;
  position: relative;
  border-top: solid rgba(0, 0, 0, 0.08);
  border-right: solid rgba(0, 0, 0, 0.08);
  border-bottom: solid rgba(0, 0, 0, 0.08);
  border-left: solid;
  transform: translateZ(0);
  animation: rotateSpinner 1.1s infinite linear;
}

@keyframes rotateSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.LoaderWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
}
