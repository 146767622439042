@import-normalize; /* bring in normalize.css styles */
@import "./theme/_vars";

body {
  margin: 0;
  font-family: $ff-roboto;
  font-weight: 500;
}

button {
  font-family: $ff-roboto;
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
}

#root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $color-deep-blue;
}

#confetti {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: $z-index-confetti;
}

// @font-face {
//   font-family: "Megogo";
//   src: url("./assets/fonts/Megogo-InterfaceRegular.woff2") format("woff2");
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Megogo";
//   src: url("./assets/fonts/Megogo-InterfaceNormal.woff2") format("woff2");
//   font-weight: 450;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Megogo";
//   src: url("./assets/fonts/Megogo-InterfaceMedium.woff2") format("woff2");
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: "Megogo";
//   src: url("./assets/fonts/Megogo-InterfaceDemiBold.woff2") format("woff2");
//   font-weight: 600;
//   font-style: normal;
//   font-display: swap;
// }
