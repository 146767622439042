.scene-container {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
  }
  
  .scene-background {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    opacity: 0.75;
    transition: opacity 0.3s ease-in-out;
    z-index: 1;
    
    &.fade-out {
      opacity: 0;
    }
  }
  
  .scene-content {
    position: relative;
    z-index: 2;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    padding: 20px;
    
    &.fade-out {
      opacity: 0;
    }
  }
  
  .scene-text {
    max-height: 60vh;
    overflow-y: auto;
    padding: 20px;
    background-color: rgba(44, 44, 44, 0.5);
    border-radius: 8px;
    margin-bottom: 20px;
    
    p {
      color: #ffffff;
      font-size: 16px;
      line-height: 1.6;
      margin-bottom: 15px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  .choices-container {
    position: fixed;
    bottom: 20px;
    left: 20px;
    right: 20px;
  }
  
  .choices-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 10px;
  }
  
  .choice-button {
    padding: 15px 20px;
    border: none;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    
    &:disabled {
      cursor: not-allowed;
      opacity: 0.7;
    }
    
    &:hover:not(:disabled) {
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
    
    &:active:not(:disabled) {
      transform: translateY(0);
    }
    
    &.choice-variant-0 { background-color: rgba(41, 128, 185, 0.9); }
    &.choice-variant-1 { background-color: rgba(52, 152, 219, 0.9); }
    &.choice-variant-2 { background-color: rgba(26, 188, 156, 0.9); }
  }

  .scene-goal {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .scene-progress {
    position: fixed;
    top: 0;
    left: 0;
    height: 20px;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background-color: rgb(255, 150, 4);
      transition: width 250ms ease-in-out;
    }
  }
  
  @media (max-width: 768px) {
    .choices-grid {
      grid-template-columns: 1fr;
    }
    
    .scene-text {
      max-height: 50vh;
    }
  }