@import "../../theme/_vars";
@import "../../theme/_animations";
@import "../../theme/_mixins";

.ProfileScreen {
  position: relative;
  width: 100%;
  height: calc(100% - 64rem);
  display: flex;
  flex-direction: column;
  padding: 80rem 20rem 20rem;
  box-sizing: border-box;
  overflow-y: auto;
  background-color: $color-deep-blue;
  animation: fadeIn 250ms ease-in forwards;

  &__Header {
    display: flex;
    align-items: center;
    padding: 20rem;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
  }

  &__HeaderGradient {
    @include header-gradient();
  }

  &__AppModeSelect {
    margin-top: 30rem;
  }

  &__BottomSheet {
    [role="dialog"] {
      background-color: $color-yellow;
    }

    [data-rsbs-header="true"]:before {
      background-color: rgba(18, 18, 18, 0.5);
    }

    &__Wrapper {
      display: flex;
      flex-direction: column;
      gap: 16rem;
      padding: 12rem 20rem 40rem;
      white-space: pre-wrap;
    }

    &__ButtonWrapper {
      display: flex;
      justify-content: center;
      gap: 16rem;

      > * {
        width: 80rem;
        height: 60rem;
        padding: 0;
      }
    }
  }

  &__TakeQuiz {
    margin: 0 auto;
  }
}
