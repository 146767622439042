@import "../../theme/_vars";

.AppModeSelect {
  display: flex;
  gap: 6rem;

  &__Item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8rem 16rem;
    box-sizing: border-box;
    border-radius: 15rem;
    text-transform: uppercase;
    font-family: $ff-roboto;
    font-size: 12rem;
    font-weight: 600;
    line-height: 14rem;
    background-color: #fff;
    transition: background-color 250ms ease-in-out;

    &_State_Selected {
      &:nth-child(1) {
        background-color: $color-green;
      }
      &:nth-child(2) {
        background-color: $color-pale-cyan;
      }
      &:nth-child(3) {
        background-color: $color-greyish-lavender;
      }
    }
  }
}
