$color-yellow: #fede67;
$color-green: #b6f36a;
$color-dark: #121212;
$color-gray: #cccccc;
$color-deep-gray: #6b6b6b;
$color-deep-blue: #232531;
$color-red: #ea3546;
$color-pale-cyan: #94dbfb;
$color-greyish-lavender: #b8b8ff;

$z-index-confetti: 20;
$z-index-desktop-stub: 15;
$z-index-navbar: 10;
$z-index-quiz-generation: 3;
$z-index-auth-screen: 2;
$z-index-list-layout: 1;
$z-index-quiz-complete: 1;
$z-index-quiz-share: 1;

$ff-roboto: "Roboto";
$ff-yanone-kaffeesatz: "Yanone Kaffeesatz";
