@import "../../../theme/_vars";

@mixin buttonStyles {
  font-family: $ff-yanone-kaffeesatz;
  border: none;
  cursor: pointer;
  background-color: transparent;
  padding: 0;
}

.Button {
  @include buttonStyles();
  position: relative;
  font-size: 20rem;
  font-weight: 500;
  line-height: 22rem;
  border-radius: 16rem;
  box-sizing: border-box;
  padding: 19rem 40rem 17rem;
  transition: background 200ms ease-in-out, color 200ms ease-in-out;

  &_IsLoading {
    pointer-events: none;
  }

  &_IsDisabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.ShareButton {
  padding: 8rem;
  line-height: normal;
  font-size: 0rem;
  border-radius: 8rem;

  svg {
    width: 24rem;
    height: 24rem;
  }
}

.BackButton {
  @include buttonStyles();
  font-size: 14rem;
  color: white;
  display: flex;
  gap: 2rem;
  align-items: center;
  padding: 8rem 12rem;
  border-radius: 16rem;
  box-sizing: border-box;
  border: 1rem solid rgba(255, 255, 255, 0.25);
  // background-color: rgba(18, 18, 18, 0.75);
  width: 81rem;
  height: 32rem;

  svg {
    width: 16rem;
    height: 16rem;
  }
}

.CloseButton {
  width: 32rem;
  height: 32rem;
  box-sizing: border-box;
  border-radius: 50%;
  border: 1rem solid rgba(255, 255, 255, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 16rem;
    height: 16rem;
  }
}
