@import "../../theme/_mixins";
@import "../../theme/_vars";

.MainScreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 72rem 0 90rem;
  overflow: auto;

  &__Content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    grid-row-gap: 20rem;

    & > * {
      padding: 0 20rem;
      box-sizing: border-box;
    }
  }

  &__RecommendedWrapper {
    display: flex;
    flex-direction: column;
    gap: 20rem;
  }

  &__Banner {
    width: 320rem;
    height: 120rem;
    margin: 0 auto;
    padding: 0;
    border-radius: 20rem;
    overflow: hidden;
  }
}

.AnonymBanner {
  width: 100%;
  border-radius: 20rem;
  background-color: #fff730;

  > img {
    width: 100%;
  }

  &__TextContainer {
    padding: 16rem 20rem 18rem;
    display: flex;
    flex-direction: column;
    gap: 8rem;
    text-align: center;
    color: $color-dark;

    > :first-child {
      font-size: 24rem;
      font-weight: 600;
    }

    > :nth-child(2) {
      color: #333;
      font-size: 16rem;
      font-weight: 450;
    }
  }
}
