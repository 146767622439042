.movie-list-container {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.version-selector {
  background-color: rgba(30, 30, 30, 0.9);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
}

.button-group {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 15px;
}

.version-button {
  padding: 12px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  line-height: 1.2;
  min-height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  &.active {
    background-color: #4a90e2;
    box-shadow: 0 4px 12px rgba(74, 144, 226, 0.3);
  }
  
  &:hover:not(.active) {
    background-color: rgba(255, 255, 255, 0.2);
  }

  small {
    display: block;
    font-size: 12px;
    opacity: 0.8;
    margin-top: 4px;
  }
}

.language-selector {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.language-button {
  padding: 10px;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: all 0.3s ease;
  
  &.active {
    background-color: #4a90e2;
    box-shadow: 0 4px 12px rgba(74, 144, 226, 0.3);
  }
  
  &:hover:not(.active) {
    background-color: rgba(255, 255, 255, 0.2);
  }
}

.movie-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.movie-button {
  width: 100%;
  padding: 15px 20px;
  border: none;
  border-radius: 8px;
  background-color: rgba(74, 144, 226, 0.9);
  color: white;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  
  &:hover {
    background-color: #4a90e2;
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(74, 144, 226, 0.3);
  }
  
  &.multilingual {
    padding-right: 140px;
  }
}

.multilingual-badge {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 0.2);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
}

@media (max-width: 600px) {
  .button-group {
    grid-template-columns: 1fr;
  }
  
  .version-button {
    min-height: auto;
    padding: 10px;
  }
  
  .language-selector {
    grid-template-columns: 1fr;
  }
  
  .movie-button {
    font-size: 14px;
    padding: 12px 15px;
    
    &.multilingual {
      padding-right: 120px;
    }
  }
  
  .multilingual-badge {
    font-size: 11px;
    padding: 3px 6px;
  }
}