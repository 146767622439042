.MyQuizzesListScreen {
  &__Title {
    color: white;
    font-size: 26rem;
    margin-bottom: 8rem;
  }

  &__Desc {
    color: rgba(255, 255, 255, 0.5);
    font-size: 16rem;
    margin-bottom: 17rem;
  }
}
