@import "../../theme/_mixins";
@import "../../theme/_vars";

.QuizzesList {
  display: flex;
  flex-direction: column;
  gap: 10rem;

  &__TextWrapper {
    display: flex;
    flex-direction: column;
    gap: 8rem;
    margin-bottom: 6rem;
  }

  &__Title {
    font-family: $ff-yanone-kaffeesatz;
    font-size: 24rem;
    color: white;
  }

  &__Description {
    font-size: 16rem;
    font-weight: 450;
    color: #ccc;
  }
}

.QuizListItem {
  position: relative;
  width: 100%;
  height: 140rem;
  padding: 14rem;
  box-sizing: border-box;
  border-radius: 20rem;
  overflow: hidden;
  display: flex;
  gap: 20rem;
  justify-content: space-between;
  align-items: flex-end;
  color: white;
  cursor: pointer;

  &_View_Stub {
    @include animated-bg-gradient();
  }

  &__Anchor {
    text-decoration: none;
  }

  &__Gradient {
    position: absolute;
    background-image: linear-gradient(
      180deg,
      rgba(18, 18, 18, 0) 0%,
      rgba(18, 18, 18, 0.7) 100%
    );
    bottom: 0;
    left: 0;
    height: 86rem;
    width: 100%;
  }

  &__Title {
    font-size: 16rem;
    font-weight: 600;
    line-height: 18rem;
    font-family: $ff-roboto;
    z-index: 1;
  }

  &__UsersCounter {
    display: flex;
    gap: 4rem;
    align-items: center;
    font-size: 16rem;
    font-family: $ff-roboto;
    z-index: 1;

    svg {
      width: 18rem;
      height: 18rem;
    }
  }

  &__ShareContainer {
    position: absolute;
    top: 14rem;
    right: 14rem;
  }

  &__Image {
    position: absolute;
    top: 0;
    left: 0;
  }
}
