@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes moveBackroundHorizontal {
  0% {
    background-position-x: 0%;
  }
  100% {
    background-position-x: -150%;
  }
}
