@import "../../theme/_mixins";
@import "../../theme/_vars";

.DesktopStub {
  @include fixed-position($z-index-desktop-stub);
  background-color: #121212;
  display: flex;
  justify-content: center;

  &__BgImg {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    background-image: url("../../assets/images/auth_img.png");
    background-position: center;
    background-size: cover;
  }

  &__Content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    > :nth-child(2) {
      // avatar
      margin-bottom: 30em;
    }

    > :nth-child(3) {
      // hello text
      margin-bottom: 15em;
    }

    > :nth-child(6) {
      // qr
      margin-bottom: 50em;
    }
  }

  &__AvatarImg {
    width: 120em;
    height: 120em;
    border-radius: 50%;
    box-shadow: 0 0 0 6em #fff730;
  }

  &__QRImg {
    width: 100em;
    height: 100em;
    border-radius: 10em;
  }
}
