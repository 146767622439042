.DifficultyLevelSelect {
  display: flex;
  flex-direction: column;
  gap: 20rem;
  margin-bottom: 30rem;

  &__Type {
    display: flex;
    flex-direction: column;

    &_State_Selected {
      & > [data-role="image"] {
        box-shadow: 0 0 0 4rem #fff;
      }
    }

    & > [data-role="image"] {
      width: 80rem;
      min-width: 80rem;
      height: 160rem;
      border-radius: 16rem;
      transition: box-shadow 250ms ease-in-out;
    }

    & > [data-role="text"] {
      margin-top: 16rem;
    }
  }
}

.LogoutButton {
  padding: 16rem 15rem;
  margin-top: 50rem;
  display: flex;
  gap: 10rem;
  align-items: center;
  box-shadow: inset 0 0 0 1rem rgba(255, 255, 255, 0.75);
  border-radius: 16rem;

  svg {
    width: 16rem;
    height: 16rem;
  }
}
